// Custom elements styles
textarea {
  font-size: 13px !important;
}

textarea::placeholder {
  font-size: 13px !important;
}

label {
  text-align: left;
  font-size: 14px;
  float: left;
}

button + button {
  margin-left: 20px;
}

a:hover {
  color: #0078AB !important;
}
// End Custom elements styles

.titleShelf {
  padding: 25px 40px 15px;
  border-bottom: 1px solid #B2C2C8;
  display: flow-root;

  .left {
    width: 55%;
    text-align: left;
    float: left;
  }

  .right {
    width: 45%;
    text-align: right;
    float: right;
    display: flex;
  }

  .smartHomeText {
    width: 70%;
    margin-right: 0px;
    font-size: 13px;
    font-style: italic;
    color: #707579;
    margin-bottom: 0px !important;
  }
  .smartHomeLabel {
    width: 30%;
    margin-bottom: 0px !important;
    display: block;
    padding-top: 10px;
    padding-right: 15px;
  }
}

.accessDetailsContainer {
  padding: 25px 40px;
  width: 100%;
  top: 60px;
  bottom: 100px;
  position: absolute;
}

.principalFormRow {
  padding-top: 0px;
  padding-bottom: 20px;
  border-bottom: 1px solid #B2C2C8;
  margin-bottom: 20px;
}

.customFormRow {
  padding-bottom: 20px;
  min-height: 80vh;
}

.customCol {
  padding: 10px 30px 0px 30px !important;
  position: relative;
}

.customRow {
  padding-bottom: 10px;
  padding-top: 10px;

  label {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }

  &_right {
    padding-bottom: 21px !important;
  }
}

.smartHomeMark {
  border: 1px solid #D62E4F;
  border-radius: 5px;
}

.textareaRow {
  margin-top: -3px;
  padding-bottom: 0px;

  &_prefix {
    margin-top: -60px !important;
    padding-bottom: 10px;
  }

  &_suffix {
    margin-top: -20px !important;
  }
}

.footerShelf {
  padding: 20px 20px 20px;
  border-top: 1px solid #B2C2C8;
  width: 100%;
  bottom:0px;
  position:absolute;
  overflow:hidden;
}

.validate {
  padding-left: 40px;
  float: left;
  width: 70%;
  display: flex;
  flex-wrap: nowrap !important;

  &_message {
    padding-top: 2px;
    padding-left: 20px;
    width: 100%;
  }
}

.customAlert {
  margin: 0px !important;
  flex-wrap: nowrap !important;
}

.button {
  padding-right: 45px;
  float: right;
}

.inputLeft {
  input {
    padding-left: 10px;
    text-align: left !important;
  }
}

.left {
  display: flex;
}

.selectLeft {
  div {
    text-align: left !important;
  }
}

.box {
  display: block;
  margin-top: 16px;

  &_text {
    text-align: left;
    line-height: 1.2;
  }

  &_toggle {
    margin-left: 2px;
    color: #0078ab;
    cursor: pointer;
  }

  &_paragraph {
    white-space: pre-line;
    font-size: 13px;
    line-height: 1.5;
  }
}

.configurationCol {
  display: flex;

  & .configInput {
    width: 90%;
  }

  & .fullConfigInput {
    width: 100%;
  }
}

.icon {
  float: right;

  > svg {
    stroke: none;
    fill: none;

    > path {
      stroke: none;
      fill: #4c5357;
    }
  }

  &_left {
    margin-left: 60px;
  }

  &_make {
    margin-left: 5px;
    margin-top: -3px;
  }

  &_config {
    padding: 4px 0px 4px 15px !important;
  }
}

.notButton {
  padding: 0px 0px;
  background-color: #ffffff !important;
  border: 0px;
  outline: 0px !important;
}

.customAutocomplete {
  margin-top: 0px !important;
  margin-bottom: 0px !important;

  input {
    font-size: 12px !important;
    color: #2c3439 !important;
    padding: 0px 4px !important;
    height: 16px !important;
  }

  input:disabled {
    border-color: #3dd5f3;
  }
}

.modalStyle {
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.paper {
  width: 400px;
  padding: 16px 32px 24px;
  position: absolute;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
}

.internalNotesDiv {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.extraInstructionsDiv {
  &_suffix {
    padding-top: 10px;
  }
  &_prefix {
    padding-bottom: 60px;
  }
}

.addNotesText {
  color: #0078AB;
  font-size: 14px;
  position: absolute;
}

.addInternalNotesText {
  text-align: right;
  width: 100%;
  padding-right: 45px;
}

.addInstructionsText {
  text-align: left;
}

.hide {
  display: none;
}