.dragAndDropRow {
    border-radius: 5px;
    border: 1px solid #D1D1D1;
    padding: 6px;
    margin-bottom: 9px;
    font-size: 14px;
    text-align: left;
    align-items: center;

    &_notValidated {
        border: 1px solid #DF343A
    }
}

.icon {
    filter: brightness(0) saturate(100%) invert(89%) sepia(2%) saturate(0%) hue-rotate(169deg) brightness(98%) contrast(93%) !important; //#D9D9D9
}

.actionCol {
    .notButton {
        padding: 0px 0px;
        border: 0px !important;
        margin: 0px 5px 0px 5px;
        background-color :rgba(0,0,0,0) !important;
    }

    .editButton {
        background-color: #CCD6DB !important;
        border-radius: 100px;
        padding: 7px;
    }


    .remove {
        filter: invert(27%) sepia(45%) saturate(3842%) hue-rotate(339deg) brightness(91%) contrast(91%) !important; //#DF343A;

        &_disable {
            filter: invert(97%) sepia(5%) saturate(40%) hue-rotate(167deg) brightness(96%) contrast(93%) !important; //#e9eaeb;
        }
    }

    .edit {
        filter: invert(22%) sepia(70%) saturate(450%) hue-rotate(153deg) brightness(92%) contrast(93%); //#1A475B
    }
}

.pillsCol {
    .customPill {
        letter-spacing: 1.5px;
        font-weight: bold;
        font-size: 8px;
        margin: 0px 3px 0px 3px;
        padding: 5px 10px;
    }

    .notesPill {
        background: #007F90 !important;
        color: #FFFFFF;
    }

    .smartHomePill {
        background: #9EB3B3 !important;
        color: #FFFFFF;
    }
}