.fa-spin {
  animation: fa-spin 2s infinite linear;
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.col-sm-3 {
  display: flex;
}
