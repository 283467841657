.summaryApp {
  width: 100%;
  padding: 10px 0px;
}

.optionsContainer {
  width: 50%;
  float: right;
  text-align: right;

  .optionLink {
    color: #0078AB;
    font-size: 14px;
  }
}

.Container {
  overflow: hidden;
}

.ContainerAccessList {
  overflow: hidden;
  width: inherit;

  .containerTable {
    border: 1px solid #E5EBED;
    border-radius: 12px;
    padding: 20px 20px 20px 50px;
    box-shadow: 0px 0px 12px 0px #0000001A;
    margin: 10px 30px 20px 30px;
    font-size: 14px;
    text-align: left;

    &_backupPending {
      border: 1px solid #D62E4F;
    }

    &_notes {
      margin: 10px 30px 20px 30px;
      padding: 30px 20px 30px 50px;
    }
  }
}

.customGrid {
  display: inline !important;
  grid-row-gap: 0px;
  grid-template-columns: 100%;

  div {
    display: flex;
    width: 100%;
  }
}

.tableHeader {
  padding: 10px 30px 10px 9px;
  font-size: 14px;
}

.purposeHeader {
  font-size: 14px;
  color: #0E3348;
  text-align: left;
  font-family: Public Sans;
  margin-left: 40px;
  letter-spacing: 2px;
}

.emptyMessage {
  color: #8099A4;
  margin-top: 45px;
  margin-bottom: 40px;
  margin-left: 10px;

  svg {
    filter: invert(60%) sepia(30%) saturate(225%) hue-rotate(153deg) brightness(93%) contrast(85%) !important;
  }

  span {
    margin-left: 15px;
  }
}

.backupMessage {
  color: #D62E4F;
  margin-top: 45px;
  margin-bottom: 40px;
  margin-left: 10px;

  svg {
    filter: invert(25%) sepia(52%) saturate(3709%) hue-rotate(331deg) brightness(88%) contrast(88%) !important;
  }

  span {
    margin-left: 15px;
  }
}

.buttonsDiv {
  display: flex;
  justify-content: space-between;
}

.left, .right{
  display: flex;
  flex-direction: column;
}

.orderButton {
  border: 1px solid #0E3348 !important;
  color: #0E3348 !important;
  border-radius: 100px !important;
  margin-left: -15px !important;
  margin-top: 5px !important;

  svg {
    transform: rotate(90deg);
    margin-left: 5px;
    filter: brightness(0) saturate(100%) invert(16%) sepia(56%) saturate(598%) hue-rotate(154deg) brightness(97%) contrast(98%) !important;
  }

  :hover {
    filter: brightness(0) saturate(100%) invert(32%) sepia(80%) saturate(1030%) hue-rotate(166deg) brightness(96%) contrast(100%) !important;
  }
}

.cancelButton {
  border-radius: 100px !important;
  margin-left: 10px !important;
  margin-top: 5px !important;
}

.addButton {
  color: #FFFFFF !important;
  background-color: #0E3348 !important;
  border-radius: 100px !important;
  margin-right: 15px !important;
  margin-top: 5px !important;

  svg {
    transform: rotate(90deg);
    margin-left: 5px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(173deg) brightness(102%) contrast(104%) !important;
  }

  :hover {
    color: #FFFFFF !important;
    filter: brightness(0) saturate(100%) invert(16%) sepia(56%) saturate(598%) hue-rotate(154deg) brightness(97%) contrast(98%) !important;
  }
}

.hide {
  display: none;
}

.divBloquear {
  position: relative;
}

/* La capa de bloqueo */
.block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Oscuridad */
  display: none; /* Inicialmente oculta */
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.message {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.message p {
  margin: 0 0 10px;
}

.close {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.cerrar:hover {
  background-color: #0056b3;
}