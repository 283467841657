.NotesContainer {
  padding: 0px 15px 0px 0px;
  margin-left: -20px;

  .NotesTextArea {
    margin-left: 5px;
    width: 100%;
  }

  .half {
    textarea {
      height: 140px;
    }
  }

  .full {
    textarea {
      height: 325px;
    }
  }

  button {
    float: right;
  }

  textarea:disabled::placeholder {
    color: #9EB3B3;
  }

  textarea:disabled {
    background-color: #FFFFFF;
  }
}

.LinkContainer {
  width: 50%;
  float: right;
  text-align: right;

  .preview {
    color: #0078AB;
    font-size: 14px;
  }

  svg {
    padding-bottom: 2px;
    filter: invert(22%) sepia(70%) saturate(450%) hue-rotate(153deg) brightness(92%) contrast(93%); //#1A475B
  }
}


.hide {
  display: none;
}